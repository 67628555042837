<div class="w-full" *ngIf="orderService.order$ | async as order">
  <div class="w-full px-8 py-6 text-white bg-monza-500">
    <span class="block text-gray-200">{{ 'Configuration.SideBar.Header' | translate }}</span>
    <span class="block text-xl font-medium" *ngIf="orderService.order$ | async as order">{{ order.name }}</span>
    <span class="block w-1/2 bg-gray-200 h-7 animate-pulse" *ngIf="!(orderService.order$ | async)"></span>
  </div>
  <div class="w-full p-8 space-y-4 bg-white">
    <!-- calculation -->
    <div *ngIf="lineItems$ | async as lineItems">
      <div class="flex justify-end w-full text-sm text-gray-500">CHF</div>
      <ng-container *ngFor="let item of lineItems; trackBy: trackById">
        <div class="flex justify-between w-full space-y-1">
          <span class="flex items-end text-gray-500">
            <span class="inline-block mr-1 font-mono text-sm">{{ item.amount }}×</span>
            <span *ngIf="item.isField || item.isSocket || item.isWall">
              {{ item.name }}
            </span>
            <span
              matTooltipPosition="above"
              [matTooltip]="(item | concatDescription : translate.currentLang) + (item.type !== 'SWG' && item.type !== 'EWG' ? ' ' + item.custom : '')"
              *ngIf="!item.isField && !item.isSocket && !item.isWall"
              class="block overflow-hidden text-ellipsis w-36 md:w-32 lg:w-40 xl:w-64 whitespace-nowrap"
            >
              {{ item | concatDescription : translate.currentLang }}  
              <ng-container *ngIf="item.type !== 'SWG' && item.type !== 'EWG'">
                {{ item.custom }}
              </ng-container>
            </span>
          </span>
          <span class="font-mono">{{ item.price | currency : ' ' : 'symbol' : '1.2-2' }}</span>
        </div>
      </ng-container>
      <div>
        <div class="flex justify-between w-full font-medium mt-4">
          <span>{{ 'Configuration.SideBar.SubTotal' | translate }}</span>
          <span class="font-mono">{{ order.priceSum | currency : ' ' : 'symbol' : '1.2-2' }}</span>
        </div>
        <hr class="my-2" />
        <app-discount></app-discount>
        <div class="flex justify-between w-full" *ngIf="order.productType !== 'AVK'">
          <span class="text-gray-500">{{ 'Configuration.SideBar.ShippingCost' | translate }}</span>
          <span class="font-mono">{{ order.deliveryFee | currency : ' ' : 'symbol' : '1.2-2' }}</span>
        </div>
        <hr class="my-2" *ngIf="order.activeDiscount || order.productType !== 'AVK'" />
        <div class="flex justify-between w-full font-medium">
          <span *ngIf="order.productType !== 'AVK'">{{ 'Configuration.SideBar.NettoPrice' | translate }}</span>
          <span *ngIf="order.productType === 'AVK'">{{ 'Configuration.SideBar.NettoPriceAVK' | translate }}</span>
          <span class="font-mono">{{ order.nettoPrice | currency : ' ' : 'symbol' : '1.2-2' }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="!(orderService.order$ | async)" class="space-y-1">
      <div class="w-full bg-gray-200 h-7 animate-pulse"></div>
      <div class="w-full bg-gray-200 h-7 animate-pulse"></div>
    </div>
    <!-- preview -->
    <!-- <hr /> -->
    <!-- upload -->
    <div class="flex items-center" *ngIf="order.productType != orderService.ProductTypeEnum.Avk && order.productType !== 'SWG' && order.productType !== 'EWG'">
      <app-input
        class="w-full"
        [matTooltip]="'Configuration.SideBar.CuttingFileTooltip' | translate"
        matTooltipPosition="above"
        matTooltipShowDelay="400"
        [isReadonly]="order.status == OrderStatusEnum.Ordered"
        [fileName]="fileName"
        [loading]="fileLoading"
        [label]="'Configuration.SideBar.CuttingFile' | translate"
        type="file"
        accept=".pdf, .jpg, .jpeg, .png"
        (onChange)="uploadFile($event)"
      >
      </app-input>
      <app-button
        *ngIf="fileName"
        [onlyIcon]="true"
        icon="delete"
        class="ml-2"
        (onClick)="orderService.removeFileFromOrder()"
      >
      </app-button>
    </div>
    <!-- <app-input
      [value]="order.note"
      [isReadonly]="order.status == 'Ordered'"
      (onChange)="orderService.updateOrder(order.id, { name: order.name, note: $event })"
      [type]="'textarea'"
      label="Notiz"
      placeholder="Teilen Sie uns ihr Feedback oder Anmerkungen zur Bestellung mit!"
    >
    </app-input> -->
    <!-- <app-button
      [isDisabled]="order.status == OrderStatusEnum.Ordered"
      (onClick)="orderService.finishOrder()"
      label="Konfiguration bestellen"
      [fullWidth]="true"
    ></app-button> -->
    <app-iframe
      (mouseenter)="targetFps(60)"
      (mouseleave)="targetFps(4)"
      *ngIf="(orderService.order$ | async)?.productType == orderService.ProductTypeEnum.Ars || (orderService.order$ | async)?.productType == orderService.ProductTypeEnum.Al"
      class="block h-80 w-full mt-4"
    ></app-iframe>
    <div
      class="flex flex-col items-start gap-4"
      *ngIf="(orderService.order$ | async)?.productType == orderService.ProductTypeEnum.Avk"
    >
      <span class="text-sm font-medium text-gray-600">
        {{ 'Configuration.SideBar.Front' | translate }}
      </span>
      <ng-container *ngIf="avkSettings$ | async as avkSettings">
        <div class="w-full h-40">
          <div class="flex gap-2 h-full">
            <div
              class="flex flex-col flex-grow h-full gap-2 order-1"
              [ngClass]="{ 'order-3': avkSettings.frontIsLeft }"
            >
              <div class="border-2 p-2 flex-grow flex items-center justify-center">
                <span class="font-bold text-center">{{ 'Configuration.SideBar.Door' | translate }}</span>
              </div>
              <div
                *ngIf="avkSettings.hasFrontHorizontalDoor"
                [ngStyle]="{
                  height: avkSettings.frontHorizontalDoorRatio
                }"
                class="border-2 p-2 w-full flex items-center justify-center"
              >
                <span class="font-bold text-center">{{ 'Configuration.SideBar.FrontH' | translate }}</span>
              </div>
            </div>
            <div
              class="flex flex-col h-full gap-2 order-2"
              [ngStyle]="{ width: avkSettings.compartmentRatio }"
              *ngIf="avkSettings.hasCompartment"
            >
              <div class="border-2 p-2 flex-grow flex items-center justify-center">
                <span class="font-bold text-center">{{ 'Configuration.SideBar.AdditionalFront' | translate }}</span>
              </div>
              <div
                *ngIf="avkSettings.hasFrontHorizontalCompartment"
                [ngStyle]="{
                  height: avkSettings.frontHorizontalCompartmentRatio
                }"
                class="border-2 p-2 w-full flex items-center justify-center"
              >
                <span class="font-bold text-center">{{ 'Configuration.SideBar.AdditionalFrontH' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <app-promotion [order]="order"></app-promotion>
    <app-button
      *ngIf="orderService.order$ | async as order"
      [routerLink]="'/overview/' + order.id"
      [label]="'Configuration.Bar.GoToOverview' | translate"
      [icon]="'keyboard-arrow-right'"
      [type]="'secondary'"
      [fullWidth]="true"
    ></app-button>
  </div>
</div>
