import { Component, Input, OnInit } from '@angular/core';
import { Order, OrderStatusEnum, Product, ProductLineItem, ProductTypeEnum } from '../../../../../api-client';
import { OrderService } from '../../../../../services/order.service';
import { Option, ReadonlyReason } from '../../../../../atoms/custom-select/custom-select.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../../../services/product.service';
import { AuthService } from '../../../../../services/auth.service';
import { BaseEWGSlotComponent } from './base-slot.component';
import { Observable, map, take, tap } from 'rxjs';
import { v4 } from 'uuid';

function onlyUnique(value: any, index: any, array: any[]) {
  return array.indexOf(value) === index;
}

function sort(a: any, b: any, index: string) {
  if (a[index] < b[index]) {
    return -1;
  }
  if (a[index] > b[index]) {
    return 1;
  }
  return 0;
}

interface Matrix {
  w: number;
  h: number;
  d: number;
}

@Component({
  selector: 'app-ewg-base, [ewgBase]',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent extends BaseEWGSlotComponent implements OnInit {
  order$ = this.orderService.order$;

  @Input()
  item!: ProductLineItem;

  @Input()
  order!: Order;

  baseProducts$: Observable<Product[]> = this.productService.getSlot(5, ProductTypeEnum.Ewg);
  filteredProducts$: Observable<{ product: Product; matrix: Matrix }[]> = this.baseProducts$.pipe(
    map((products) => products.slice(0, 7).map((prod) => ({ product: prod, matrix: this.getProductDimensions(prod) })))
  );

  selectedAmount!: Option<number>;
  amountOptions: Option<number>[] = new Array(10)
    .fill('')
    .map((_, i) => ({ label: (i + 1).toString(), value: i + 1, description: '' }));

  matrix: { w: number; h: number; d: number; v4a: boolean }[] = [];

  selectedWidth!: Option<number>;
  widths: Option<number>[] = [];

  selectedHeight!: Option<number>;
  heights: Option<number>[] = [];

  selectedDepth!: Option<number>;
  depths: Option<number>[] = [];

  isV4A = false;

  constructor(
    private readonly orderService: OrderService,
    private readonly translate: TranslateService,
    private readonly productService: ProductService,
    private readonly auth: AuthService
  ) {
    super(translate);
    this.baseProducts$.subscribe((products) => {
      this.matrix = products.map(this.getProductDimensions);
      this.widths = [
        { label: 'Alle', description: '', value: 0 },
        ...this.matrix
          .sort((a, b) => sort(a, b, 'w'))
          .map((m) => m.w)
          .filter(onlyUnique)
          .map((m) => ({ label: m.toString(), description: '', value: m }))
      ];
      this.selectedWidth = this.widths.find((w) => w.value === 600) || this.widths[0];
      this.heights = [
        { label: 'Alle', description: '', value: 0 },
        ...this.matrix
          .sort((a, b) => sort(a, b, 'h'))
          .map((m) => m.h)
          .filter(onlyUnique)
          .map((m) => ({ label: m.toString(), description: '', value: m }))
      ];
      this.selectedHeight = this.heights[0];
      this.depths = [
        { label: 'Alle', description: '', value: 0 },
        ...this.matrix
          .sort((a, b) => sort(a, b, 'd'))
          .map((m) => m.d)
          .filter(onlyUnique)
          .map((m) => ({ label: m.toString(), description: '', value: m }))
      ];
      this.selectedDepth = this.depths[0];
      this.updateWidths();
      this.updateDepths();
      this.updateHeights();
    });
  }

  updateProduct(prod: Product, matrix: Matrix) {
    this.orderService
      .updateLineItemSlot(this.item.id, '5', {
        width: matrix.w,
        height: matrix.h,
        depth: matrix.d,
        productNumber: prod.productNumber
      })
      .then(() => {
        this.orderService.updateLineItem(this.item.id, {
          width: matrix.w,
          height: matrix.h,
          depth: matrix.d,
          productNumber: prod.productNumber
        });
      });
  }

  getProductDimensions(product: Product) {
    const split = product.description
      .split('x')
      .map((s) => parseInt(s.split('mm')[0].trim()))
      .filter((x) => !isNaN(x));

    return {
      w: split[0],
      h: split[1],
      d: split[2],
      v4a: product.productNumber.includes('V4A')
    };
  }

  filterBaseProducts() {
    this.filteredProducts$ = this.productService.getSlot(5, ProductTypeEnum.Ewg).pipe(
      map((products) =>
        products
          .sort((a, b) => sort(this.getProductDimensions(a), this.getProductDimensions(b), 'w'))
          .sort((a, b) => sort(this.getProductDimensions(a), this.getProductDimensions(b), 'h'))
          .sort((a, b) => sort(this.getProductDimensions(a), this.getProductDimensions(b), 'd'))
          .filter((p) => {
            const dim = this.getProductDimensions(p);
            return (
              (this.selectedWidth.value === 0 ? true : dim.w === this.selectedWidth.value) &&
              (this.selectedHeight.value === 0 ? true : dim.h === this.selectedHeight.value) &&
              (this.selectedDepth.value === 0 ? true : dim.d === this.selectedDepth.value) &&
              (this.isV4A ? p.productNumber.includes('V4A') : !p.productNumber.includes('V4A'))
            );
          })
          .slice(0, 7)
          .map((prod) => ({ product: prod, matrix: this.getProductDimensions(prod) }))
      ),
      tap((products) => {
        if (products.length === 1 && this.item.productNumber !== products[0].product.productNumber) {
          this.item.productNumber = products[0].product.productNumber;
          this.updateProduct(products[0].product, products[0].matrix);
        }
      })
    );
  }

  matrixContains(w: number, h: number, d: number) {
    return this.matrix.some(
      (m) =>
        (w === 0 ? true : m.w === w) &&
        (h === 0 ? true : m.h === h) &&
        (d === 0 ? true : m.d === d) &&
        m.v4a === this.isV4A
    );
  }

  updateWidths() {
    this.widths = this.widths.map((w) => {
      if (w.value == 0) {
        w.readonly = false;
        return w;
      }
      if (!this.matrixContains(w.value, this.selectedHeight.value, this.selectedDepth.value)) {
        w.readonly = true;
      } else {
        w.readonly = false;
      }
      return w;
    });

    this.selectedWidth =
      this.widths.filter((w) => !w.readonly).find((w) => w.value === this.selectedWidth.value) || this.widths[0];
  }

  updateHeights() {
    this.heights = this.heights.map((h) => {
      if (h.value == 0) {
        h.readonly = false;
        return h;
      }
      if (!this.matrixContains(this.selectedWidth.value, h.value, this.selectedDepth.value)) {
        h.readonly = true;
      } else {
        h.readonly = false;
      }
      return h;
    });

    this.selectedHeight =
      this.heights.filter((h) => !h.readonly).find((h) => h.value === this.selectedHeight.value) || this.heights[0];
  }

  updateDepths() {
    this.depths = this.depths.map((d) => {
      if (d.value == 0) {
        d.readonly = false;
        return d;
      }
      if (!this.matrixContains(this.selectedWidth.value, this.selectedHeight.value, d.value)) {
        d.readonly = true;
      } else {
        d.readonly = false;
      }
      return d;
    });

    this.selectedDepth =
      this.depths.filter((d) => !d.readonly).find((d) => d.value === this.selectedDepth.value) || this.depths[0];
  }

  updateWidth(width: Option<number>) {
    this.selectedWidth = width;
    this.updateHeights();
    this.updateDepths();
    this.filterBaseProducts();
  }

  updateHeight(height: Option<number>) {
    this.selectedHeight = height;
    this.updateWidths();
    this.updateDepths();
    this.filterBaseProducts();
  }

  updateDepth(depth: Option<number>) {
    this.selectedDepth = depth;
    this.updateWidths();
    this.updateHeights();
    this.filterBaseProducts();
  }

  updateV4A() {
    this.updateWidths();
    this.updateHeights();
    this.updateDepths();
    this.filterBaseProducts();
  }

  override initItem(): void {}

  updateAmount(amount: number): void {
    this.orderService.updateLineItem(this.item.id, { amount });
  }

  override updateItem(firstChange?: boolean | undefined): void {
    this.selectedAmount = this.amountOptions.find((a) => a.value === this.item.amount) || this.amountOptions[0];
    this.updateWidths();
    this.updateHeights();
    this.updateDepths();
    this.filterBaseProducts();
  }
}
